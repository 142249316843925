import { createAction, props } from '@ngrx/store';

import { IcsCalendars } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Ics Calendars] Load Request',
  props<{
    params: SearchParams;
  }>(),
);

export const loadSuccess = createAction(
  '[Ics Calendars] Load Success',
  props<{ data: IcsCalendars }>(),
);

export const loadFailure = createAction(
  '[Ics Calendars] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Ics Calendars] Reset State');
