import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getData = (state: State) => state.data;

export const selectIcsCalendarsState = createFeatureSelector<State>(
  'icsCalendars',
);

export const selectIcsCalendarsData = createSelector(
  selectIcsCalendarsState,
  getData,
);

export const selectIcsCalendarsError = createSelector(
  selectIcsCalendarsState,
  getError,
);

export const selectIcsCalendarsIsLoading = createSelector(
  selectIcsCalendarsState,
  getIsLoading,
);
