import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { IcsCalendarsStoreEffects } from './effects';
import { IcsCalendarsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('icsCalendars', IcsCalendarsReducer),
    EffectsModule.forFeature([IcsCalendarsStoreEffects]),
  ],
})
export class IcsCalendarsStoreModule {}
