import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class IcsCalendarsService {
  constructor(private http: HttpClient) {}

  load(params: SearchParams) {
    return this.http.get(
      `properties/ics_reservations?${generateSearchQuery(params)}`,
    );
  }
}
